<template>
    <v-card color="white">
        <v-card-text style="font-size: 12px">
            <h2 class="blue--text">{{legalBinding.name}}</h2> 
            <span><b>Articles:</b> <br> {{legalBinding.articles}}</span>
            <p class="pa-0 ma-0" style="font-size: 12px"><b>Details:</b> <br>   {{legalBinding.description}}</p>
        </v-card-text>
    </v-card>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                legalBinding: [],
            }
        },

        methods:{
            getLegalBindings(){
                const legal_binding_id = this.$route.params.key  // get campaign category from url and send request to server
                axios
                    .get('/api/v1/home/get/legal_bindings/info/'+legal_binding_id)
                    .then(response => {
                        this.legalBinding = response.data  // get the data and fill into campaigns
                        console.log(this.legalBinding)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getLegalBindings();
            this.$store.commit('setCloseURL', "/savings/legal_binding/all")
        },
    }
</script>

